var $ = jQuery.noConflict();

document.addEventListener("DOMContentLoaded", function(){
  /*** Script For SVG CODE  ***/
  jQuery("img.svg").each(function () {
    var $img = jQuery(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");
    jQuery.get(
      imgURL,
      function (data) {
        /*** Get the SVG tag, ignore the rest ***/
        var $svg = jQuery(data).find("svg");

        /*** Add replaced image's ID to the new SVG ***/
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        /*** Add replaced image's classes to the new SVG ***/
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }
        /*** Remove any invalid XML tags as per http://validator.w3.org ***/
        $svg = $svg.removeAttr("xmlns:a");
        /*** Check if the viewport is set, else we gonna set it if we can. ***/
        if (
          !$svg.attr("viewBox") &&
          $svg.attr("height") &&
          $svg.attr("width")
        ) {
          $svg.attr(
            "viewBox",
            "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
          );
        }
        /*** Replace image with new SVG ***/
        $img.replaceWith($svg);
      },
      "xml"
    );
  }); 


  $(".menu-item-has-children").prepend('<i class="caret"></i>');
  $("#mobile-menu .menu-item-has-children .sub-menu").prepend('<li class="menu-item menu-back"><a><img src="https://kornberglawfirm.com/wp-content/themes/beardog/assets/images/left-arrow.svg" alt="arrow"> Back</a></li>');

  $(".menu-item-has-children .caret").on('click', function(){
    $(this).parent().toggleClass("menu-show");
  });
  $(".menu-back").on('click', function(){
    $(this).closest('li.menu-show').removeClass("menu-show");
  });

  $(window).on('scroll', function() {
    if ($(this).scrollTop()) {
      $('#toTop').fadeIn();
    } else {
      $('#toTop').fadeOut();
    }
  });  

  $("#toTop").on('click', function (e) {
    e.preventDefault();
    $("html, body").animate({scrollTop: 0}, 800);
  });

  $("a[href='#contact-form'], .single-content a[href='https://kornberglawfirm.com/contact/']").on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({scrollTop: $('#footer-form').offset().top -120 }, 'slow');
  });
  
  $(".header-search-icon-img").on('click', function(){
    $(".search-form").toggleClass("form-open");
  });

  /*** Header fixed ***/
  $(window).on('scroll', function() {
    var scrollPixel = $(window).scrollTop();
    if (scrollPixel < 30) {
      $("header").removeClass("header-fix");
    } else {
      $("header").addClass("header-fix");
    }
  });

 // $('.wpcf7-textarea').on("cut copy paste",function(e) {e.preventDefault();});

 if ($('#pills-tab').length > 0) {
  var hash = window.location.hash;
  if (hash && $(hash).length > 0) {
    try {
      var tabTrigger = new bootstrap.Tab($(hash)[0]);
      tabTrigger.show();
      $('html, body').animate({scrollTop: $(hash).offset().top - 90}, 'slow');
    } catch (error) {
      console.error('Error triggering tab:', error);
    }
  }  
}
 
});

document.addEventListener( 'wpcf7mailsent', function( event ) {
  if ( '13617' == event.detail.contactFormId ) {
    location = 'https://kornberglawfirm.com/thank-you-sidebar/';
  } else {
    location = 'https://kornberglawfirm.com/thank-you/';
  }
 }, false );